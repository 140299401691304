import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TableLoading from "../../components/skeleton/TableLoading";
import ClassCategoryTable from "../../components/tables/primeTable/classes/ClassCategoryTable";
import { FaPlus } from "react-icons/fa";

const ClassCategories = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [classCategoryData, setClassCategoryData] = useState([]);
  const [classModalVisibility, setClassModalVisibility] = useState(false);
  const [selectedClassCategoryDetail, setSelectedClassCategoryDetail] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [modalType, setModalType] = useState("update");

  useEffect(() => {
    // fetch classes
    try {
      getClassCategories();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load classes. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { categoryAdded: false, categoryUpdated: false },
        });
      }
    }
  }, []);

  // function to get all classes
  const getClassCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassCategoryData([]);
      } else {
        setClassCategoryData(response_data.response);
      }

      // check if a new class was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const categoryAdded = !empty(paramState.categoryAdded)
          ? paramState.categoryAdded
          : false;
        const categoryUpdated = !empty(paramState.categoryUpdated)
          ? paramState.categoryUpdated
          : false;
        if (categoryAdded === true || categoryUpdated === true) {
          const actionType = categoryAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Class ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { categoryAdded: false, categoryUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  //function to toggle class visibility
  const toggleClassVisiblity = async () => {
    try {
      if (classModalVisibility) setClassModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const active =
        !empty(selectedClassCategoryDetail) &&
        !empty(selectedClassCategoryDetail.active)
          ? "Yes"
          : "No";
      const categoryId =
        !empty(selectedClassCategoryDetail) &&
        !empty(selectedClassCategoryDetail._id)
          ? selectedClassCategoryDetail._id
          : "";
      const response = await classApi.toggleClassVisibility(
        categoryId,
        active,
        schoolId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to ${
                !empty(selectedClassCategoryDetail) &&
                !empty(selectedClassCategoryDetail.active) &&
                selectedClassCategoryDetail.active === false
                  ? "enable"
                  : "disable"
              } class with title, ${
                !empty(selectedClassCategoryDetail.title)
                  ? selectedClassCategoryDetail.title
                  : ""
              }`;
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Class, ${
          !empty(selectedClassCategoryDetail.title)
            ? selectedClassCategoryDetail.title
            : ""
        }, was ${
          !empty(selectedClassCategoryDetail) &&
          !empty(selectedClassCategoryDetail.active) &&
          selectedClassCategoryDetail.active === true
            ? "disabled"
            : "enabled"
        } successfully!`
      );
      return getClassCategories();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  // delete class category
  const deleteClassCategory = async () => {
    try {
      if (classModalVisibility) setClassModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const categoryId =
        !empty(selectedClassCategoryDetail) &&
        !empty(selectedClassCategoryDetail._id)
          ? selectedClassCategoryDetail._id
          : "";
      const response = await classApi.deleteClassCategory(categoryId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to delete class";
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Class, ${
          !empty(selectedClassCategoryDetail.title)
            ? selectedClassCategoryDetail.title
            : ""
        }, was deleted successfully!`
      );
      return getClassCategories();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to hide confirm modal
  const hideClassModal = () => {
    setClassModalVisibility(false);
  };

  //go to update class page
  const gotToUpdateClassCategory = (data) => {
    const categoryId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/class/category/${categoryId}/update`);
  };

  // delete class
  const showDeleteClassCategoryModal = (data) => {
    setModalType("delete");
    setSelectedClassCategoryDetail(data);
    setClassModalVisibility(true);
  };

  const classModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideClassModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() =>
          modalType === "update"
            ? toggleClassVisiblity()
            : deleteClassCategory()
        }
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Category"
              link="/class/categories/add"
              title="Class Categories"
              icon={<FaPlus />}
              iconMarginRight={5}
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <ClassCategoryTable
                classes={classCategoryData}
                goToUpdateClassCategoryScreen={gotToUpdateClassCategory}
                goToDeleteClassCategoryScreen={showDeleteClassCategoryModal}
              />
            ) : (
              <TableLoading rows={20} cols={3} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={classModalVisibility}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={classModalFooter}
        onHide={hideClassModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {modalType === "update" ? (
                !empty(selectedClassCategoryDetail) &&
                !empty(selectedClassCategoryDetail.active) &&
                selectedClassCategoryDetail.active ? (
                  <span style={{ color: "red" }}>
                    <strong>disable</strong>
                  </span>
                ) : (
                  <span style={{ color: "green" }}>
                    <strong>enable</strong>
                  </span>
                )
              ) : (
                <span style={{ color: "red" }}>
                  <strong>delete</strong>
                </span>
              )}{" "}
              the selected class category -{" "}
              <strong>
                {!empty(selectedClassCategoryDetail) &&
                !empty(selectedClassCategoryDetail.title)
                  ? selectedClassCategoryDetail.title
                  : ""}
              </strong>
              {selectedClassCategoryDetail && (
                <b>
                  {!empty(selectedClassCategoryDetail.name)
                    ? selectedClassCategoryDetail.name
                    : ""}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default ClassCategories;
