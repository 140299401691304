import "./Sidebar.css";
import {
  FaPowerOff,
  FaUserGraduate,
  FaUserTie,
  FaTh,
  FaBook,
  FaLayerGroup,
  FaListAlt,
  FaCheckSquare,
  FaBookOpen,
  FaCog,
  FaSwatchbook,
  FaBox,
  FaChalkboard,
  FaMoneyBillAlt,
  FaBookmark,
  FaEnvelope,
  FaBell,
  FaUserCheck,
  FaHouseUser,
  FaBookReader,
  FaClock,
} from "react-icons/fa";
import {
  MdChat,
  MdChatBubble,
  MdDomain,
  MdPsychology,
  MdRateReview,
  MdSupervisorAccount,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useContext } from "react";

// hooks
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty } from "../../Utilities/utils";

// images
import appLogo from "../../assets/logo/blue-logo.png";

const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const schoolAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  const sessionTitle =
    !empty(user) && !empty(user.currentSessionTitle)
      ? user.currentSessionTitle
      : "";
  const termTitle =
    !empty(user) && !empty(user.currentTermTitle) ? user.currentTermTitle : "";
  const reverseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar_title mt-20">
        <div className="flex flex-center direction-column align-center sidebar-image-container">
          <img
            className="sidebar-header-image"
            src={!empty(schoolAvatar) ? schoolAvatar : appLogo}
            alt="logo"
          />
          <p className="text-black text-center mt-20">
            <span>
              {sessionTitle} | {termTitle}
            </span>
          </p>
        </div>
      </div>

      <div className="sidebar_menu">
        {/* sidebar link */}
        <div className="separator">Home</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaTh className="sidebar_link_icon" />
              <span className="sidebar_link_text">Dashboard</span>
            </div>
          )}
        </NavLink>

        <div className="separator">Users</div>
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/students"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserGraduate className="sidebar_link_icon" />
              <div className="sidebar_link_text">Students</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/staff"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserTie className="sidebar_link_icon" />
              <div className="sidebar_link_text">Staff</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link " : "sidebar_link"}>
              <MdSupervisorAccount className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Parents/Guardians
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>

        <div className="separator">Academics</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/attendance/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserCheck className="sidebar_link_icon" />
              <div className="sidebar_link_text">Attendance</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/classes"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaHouseUser className="sidebar_link_icon" />
              <div className="sidebar_link_text">Classes</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/class/categories"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBox className="sidebar_link_icon" />
              <div className="sidebar_link_text">Class Categories</div>
            </div>
          )}
        </NavLink>

        <NavLink
          onClick={() => reverseSidebar()}
          to="/subjects"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBookOpen className="sidebar_link_icon" />
              <div className="sidebar_link_text">Subjects</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          // to="/time-table/home"
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaClock className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Time Table
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/disciplines"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBookReader className="sidebar_link_icon" />
              <div className="sidebar_link_text">Disciplines</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/sessions"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaLayerGroup className="sidebar_link_icon" />
              <div className="sidebar_link_text">Sessions</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/terms"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaListAlt className="sidebar_link_icon" />
              <div className="sidebar_link_text">Terms</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/graduation-years"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaHouseUser className="sidebar_link_icon" />
              <div className="sidebar_link_text">Graduated Students</div>
            </div>
          )}
        </NavLink>

        <div className="separator">Assessment</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/set"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBook className="sidebar_link_icon" />
              <div className="sidebar_link_text">Assessment</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/rating-indices"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <MdRateReview className="sidebar_link_icon" />
              <div className="sidebar_link_text">Rating Indices</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/psychomotor"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <MdPsychology className="sidebar_link_icon" />
              <div className="sidebar_link_text">Psychomotor</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/affective-domains"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <MdDomain className="sidebar_link_icon" />
              <div className="sidebar_link_text">Affective Domain</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/position"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaSwatchbook className="sidebar_link_icon" />
              <div className="sidebar_link_text">Position</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/result-checker"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaCheckSquare className="sidebar_link_icon" />
              <div className="sidebar_link_text">Result Checker</div>
            </div>
          )}
        </NavLink>

        <div className="separator">Misc</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link " : "sidebar_link"}>
              <FaChalkboard className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Blackboard
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaMoneyBillAlt className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Finance
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaBookmark className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Activities
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>

        <div className="separator">Messages</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaEnvelope className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Emails
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <MdChatBubble className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Chat
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="#"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div className={isActive ? "sidebar_link" : "sidebar_link"}>
              <FaBell className="sidebar_link_icon" />
              <div className="sidebar_link_text">
                Notifications
                <span className="coming-soon">&nbsp;&nbsp;Coming soon</span>
              </div>
            </div>
          )}
        </NavLink>

        <div className="separator">Settings</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings/home"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaCog className="sidebar_link_icon" />
              <div className="sidebar_link_text">Settings</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <div className="sidebar_logout">
          <NavLink to="/logout" className="sidebar_logout_text">
            <FaPowerOff className="sidebar_logout_icon" />
            Logout
          </NavLink>
        </div>
        <div style={{ marginTop: 10 }}>&nbsp;</div>
      </div>
    </div>
  );
};

export default Sidebar;
