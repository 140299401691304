import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// api
import schoolApi from "../../api/School";
import classApi from "../../api/Classes";

//css
import "../dashboard/Dashboard.css";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";

//data
import {
  empty,
  generateTimeSlots,
  isArray,
  isDuringBreak,
  isNormalTimeInterval,
  prepareResponseData,
  timeStringToDate,
} from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import TimeTableWrapper from "../../components/appWrapper/TimeTableWrapper";
import colors from "../../config/colors";

const TimeTableDashboard = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const normalClassInterval = 45;
  const lessonInterval =
    !empty(user) &&
    !empty(user.periodSettings) &&
    !empty(user.periodSettings.lessonClassInterval)
      ? parseInt(user.periodSettings.lessonClassInterval)
      : 30;
  const lessonStartTime =
    !empty(user) &&
    !empty(user.classClockInOut) &&
    !empty(user.classClockInOut.lessonStartTime)
      ? user.classClockInOut.lessonStartTime
      : "03:00 PM";
  const schoolOpenDays =
    !empty(user) &&
    !empty(user.schoolClockInOut) &&
    !empty(user.schoolClockInOut.schoolOpenDays)
      ? user.schoolClockInOut.schoolOpenDays
      : [];
  const schoolClockInOut =
    !empty(user) && !empty(user.schoolClockInOut) ? user.schoolClockInOut : {};
  const classStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.classStartTime)
      ? schoolClockInOut.classStartTime
      : "08:00 AM";
  const classDismissalTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.schoolDismissalTime)
      ? schoolClockInOut.schoolDismissalTime
      : "04:00 PM";
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(0);
  const [schoolClassSchedules, setSchoolClassSchedules] = useState([]);
  const [periodSection, setPeriodSection] = useState("");
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [selectedNumberOfPeriods, setSelectedNumberOfPeriods] = useState(0);
  const [startTime, setStartTime] = useState(classStartTime);

  const toastTR = useRef(null);

  // get school details
  const breakDetails =
    !empty(user) &&
    !empty(user.periodSettings) &&
    !empty(user.periodSettings.breakDetails)
      ? user.periodSettings.breakDetails
      : [];

  useEffect(() => {
    if (schoolClassSchedules.length > 0) {
      getClasses();
    }
  }, [schoolClassSchedules]);

  useEffect(() => {
    getSchoolClassSchedules();
  }, [user]);

  useEffect(() => {
    try {
    } catch (error) {}
  }, [selectedSubjectId]);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getSchoolClassSchedules = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getSchoolClassSchedules(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.response) {
        setSchoolClassSchedules([]);
        if (
          !empty(
            response_data.response && typeof response_data.response === "string"
          )
        ) {
          return responseDailog(
            "error",
            "Error Alert",
            !empty(response_data.response)
              ? typeof response_data.response === "string"
                ? response_data.response
                : "Failed to fetch school class schedules!"
              : "Failed to fetch school class schedules"
          );
        }
        return;
      }

      const data =
        !empty(response_data) &&
        !empty(response_data.response) &&
        isArray(response_data.response)
          ? response_data.response
          : [];

      return setSchoolClassSchedules(data);
    } catch (error) {
      return responseDailog(
        "error",
        "Class Schedules",
        "Internal server error."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getClasses = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.response) {
        setClasses([]);
        if (
          !empty(
            response_data.response && typeof response_data.response === "string"
          )
        ) {
          return responseDailog(
            "error",
            "Error Alert",
            !empty(response_data.response)
              ? typeof response_data.response === "string"
                ? response_data.response
                : "Failed to fetch classes!"
              : "Failed to fetch classes"
          );
        }
        return;
      }

      const data =
        !empty(response_data) &&
        !empty(response_data.response) &&
        isArray(response_data.response)
          ? response_data.response
          : [];

      return setClasses(data);
    } catch (error) {
      return responseDailog("error", "Classes", "Internal server error.");
    } finally {
      setIsLoading(false);
    }
  };

  const timeSlots = generateTimeSlots(
    normalClassInterval,
    lessonInterval,
    classStartTime,
    classDismissalTime,
    lessonStartTime,
    breakDetails
  );

  // set data for opening schedule modal
  const openUpdateModal = (params) => {
    try {
      const {
        numberOfPeriods,
        day,
        startTime: _startTime,
        endTime: _endTime,
        periodSection: _periodSection,
        subject,
        classId: _classId,
      } = params;
      const subjectId =
        !empty(subject) && !empty(subject.subjectId) ? subject.subjectId : "";

      if (!empty(selectedSubjectId) && selectedSubjectId === subjectId)
        setSelectedSubjectId("");
      if (!empty(periodSection)) setPeriodSection("");
      if (!empty(selectedNumberOfPeriods)) setSelectedNumberOfPeriods("");
      if (!empty(startTime)) setSelectedNumberOfPeriods("");
      navigate("/class-schedule/add-single", {
        state: {
          classId: _classId,
          periodSection: _periodSection,
          day,
          numberOfPeriods,
          startTime: _startTime,
          endTime: _endTime,
        },
      });
    } catch (error) {}
  };

  return (
    <>
      <TimeTableWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="School Time Table" />
          {/* end menu header */}

          {/* time table start */}
          <div
            style={{
              maxHeight: "75vh",
              overflowY: "auto",
            }}
          >
            <table className="time-table-view">
              <thead
                style={{
                  position: "sticky",
                  zIndex: 10,
                  top: 0,
                  backgroundColor: colors.primary,
                  color: colors.white,
                }}
              >
                <tr>
                  <th>Day</th>
                  <th>Class/Time</th>
                  {timeSlots.map((slot, index) => (
                    <th className="fs-12 time-table-intervals" key={index}>
                      {slot.startTime} - {slot.endTime}
                    </th>
                  ))}
                </tr>
              </thead>
              {!empty(schoolClassSchedules) && (
                <tbody>
                  {schoolOpenDays.map((day) =>
                    classes.map((item, classIndex) => {
                      const title =
                        !empty(item) && !empty(item.title) ? item.title : "";
                      const classId =
                        !empty(item) && !empty(item._id) ? item._id : "";

                      return (
                        <tr
                          className="time-table-row bold"
                          key={`${day}-${classIndex}`}
                        >
                          {classIndex === 0 && (
                            <td
                              className="time-table-day"
                              rowSpan={classes.length}
                            >
                              {day}
                            </td>
                          )}
                          <td className="time-table-subject py-10 fs-12">
                            {title}
                          </td>
                          {timeSlots.map((slot, index) => {
                            // Check if this time slot is covered by a schedule item
                            const _slotStartTime =
                              !empty(slot) && !empty(slot.startTime)
                                ? timeStringToDate(slot.startTime).getTime()
                                : "";
                            const scheduleItem = schoolClassSchedules.find(
                              (schedItem) => {
                                const _classData =
                                  !empty(schedItem) && !empty(schedItem.class)
                                    ? schedItem.class
                                    : {};
                                const _classId =
                                  !empty(_classData) &&
                                  !empty(_classData.classId)
                                    ? _classData.classId
                                    : "";
                                const _day =
                                  !empty(schedItem) && !empty(schedItem.day)
                                    ? schedItem.day
                                    : "";
                                const _startTime =
                                  !empty(schedItem) &&
                                  !empty(schedItem.startTime)
                                    ? timeStringToDate(
                                        schedItem.startTime
                                      ).getTime()
                                    : "";
                                const _endTime =
                                  !empty(schedItem) && !empty(schedItem.endTime)
                                    ? timeStringToDate(
                                        schedItem.endTime
                                      ).getTime()
                                    : "";

                                return (
                                  _day === day &&
                                  _classId === classId &&
                                  _startTime <= _slotStartTime &&
                                  _endTime > _slotStartTime
                                );
                              }
                            );

                            // If the schedule spans multiple periods
                            if (scheduleItem) {
                              const numberOfPeriods =
                                !empty(scheduleItem) &&
                                !empty(scheduleItem.nubmerOfPeriods)
                                  ? parseInt(scheduleItem.nubmerOfPeriods)
                                  : 1;
                              const isBreak = isDuringBreak(
                                slot.startTime,
                                slot.endTime,
                                breakDetails
                              );
                              const startTime =
                                !empty(scheduleItem) &&
                                !empty(scheduleItem.startTime)
                                  ? scheduleItem.startTime
                                  : "";
                              const endTime =
                                !empty(scheduleItem) &&
                                !empty(scheduleItem.endTime)
                                  ? scheduleItem.endTime
                                  : "";
                              const periodSection =
                                !empty(scheduleItem) &&
                                !empty(scheduleItem.periodSection)
                                  ? scheduleItem.periodSection
                                  : "Normal Time";
                              const staff =
                                !empty(scheduleItem) &&
                                !empty(scheduleItem.staff)
                                  ? scheduleItem.staff
                                  : "";
                              const classDetails =
                                !empty(scheduleItem) &&
                                !empty(scheduleItem.class)
                                  ? scheduleItem.class
                                  : "";
                              const subject =
                                !empty(scheduleItem) &&
                                !empty(scheduleItem.subject)
                                  ? scheduleItem.subject
                                  : "";

                              // Render the schedule item with numberOfPeriods
                              return (
                                <td
                                  className="fs-12 time-table-subject-title pointer"
                                  key={index}
                                  colSpan={numberOfPeriods}
                                  onClick={() => {
                                    if (isBreak) return;
                                    openUpdateModal({
                                      numberOfPeriods,
                                      day,
                                      startTime,
                                      endTime,
                                      periodSection,
                                      staff,
                                      classDetails,
                                      subject,
                                    });
                                  }}
                                >
                                  {scheduleItem?.subject?.subjectTitle || " - "}
                                  {/* You may replace this with the subject name */}
                                </td>
                              );
                            }

                            // Check if the current time slot is during a break
                            if (
                              isDuringBreak(
                                slot.startTime,
                                slot.endTime,
                                breakDetails
                              )
                            ) {
                              return (
                                <td
                                  className="fs-12 time-table-break"
                                  key={index}
                                  colSpan={1}
                                >
                                  Break
                                </td>
                              );
                            }

                            // check period section
                            const isNormalTime = isNormalTimeInterval(
                              slot.startTime,
                              slot.endTime,
                              normalClassInterval
                            );
                            // If no schedule item matches this slot, render an empty cell
                            return (
                              <td
                                className="time-table-empty pointer"
                                key={index}
                                onClick={() => {
                                  openUpdateModal(
                                    {
                                      numberOfPeriods: 1,
                                      periodSection: isNormalTime
                                        ? "Normal Time"
                                        : "Lesson Time",
                                      startTime: slot.startTime,
                                      endTime: slot.endTime,
                                      day,
                                      classId,
                                    },
                                    "add_schedule"
                                  );
                                }}
                              >
                                {" "}
                                -{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              )}
            </table>
          </div>
          {/* time table end */}
        </main>
        <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
      </TimeTableWrapper>
    </>
  );
};

export default TimeTableDashboard;
