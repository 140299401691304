import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { empty, isObject, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";

// api
import classApi from "../../api/Classes";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import DoughnutChart from "../../components/chart/doughnut/DoughnutChart";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaExclamationTriangle,
  FaForward,
  FaRegPlusSquare,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import BoxLoading from "../../components/skeleton/BoxLoading";
import DonutChartLoading from "../../components/skeleton/DonutChartLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import FullPageLoader from "../../components/loader/FullPageLoader";
import colors from "../../config/colors";

const ClassProfile = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [noOfStudentsLoading, setNoOfStudentsLoading] = useState(false); //(WIP)
  const [classData, setClassData] = useState({});
  const [numOfStudents, setNumOfStudents] = useState(0);
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [students, setStudents] = useState([]);
  const [genderCounts, setGenderCounts] = useState({ male: 0, female: 0 });
  const [subjects, setSubjects] = useState([]); // for all class registered subjects (WIP)
  const [sessionId, setSessionId] = useState(""); // for stats if selected (WIP)
  const [termId, setTermId] = useState(0); // // for stats if selected (WIP)
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [subjectsInClassLoading, setSubjectsInClassLoading] = useState(false);
  const [noOfSubjectsLoading, setNoOfSubjectsLoading] = useState(false);
  const [assessmentPercentageLoading, setAssessmentPercentageLoading] =
    useState(false);
  const [classPerformanceLoading, setClassPerformanceLoading] = useState(false);
  const [noOfStudentsByGenderLoading, setNoOfStudentsByGenderLoading] =
    useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { classId } = useParams();
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    if (!classId) {
      navigate("/404");
    }
    checkAuthorization();
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      getNumberOfStudentsByGender();
      getAssessmentPercentage();
      getNumOfSubjects();
      getNumOfStudents();
      getClassDetails();
      getSubjectsInClass();
      getStudentsInClassPerformance();
    }
  }, [isAuthorized, classId, user]);

  const checkAuthorization = () => {
    try {
      let authorizedClasses = [];

      // check if user is authorized to view class details
      const userAuthorizations =
        !empty(user) && !empty(user.authorizations) ? user.authorizations : [];
      const classesObj = userAuthorizations.find((obj) =>
        obj.hasOwnProperty("classes")
      );
      authorizedClasses =
        !empty(classesObj) && !empty(classesObj.classes)
          ? classesObj.classes
          : [];
      if (
        !empty(authorizedClasses) &&
        (authorizedClasses.includes("All") ||
          authorizedClasses.includes(classId))
      ) {
        setIsAuthorized(true);
      }
    } catch (error) {
      responseDailog("error", "Internal server error", "Something went wrong.");
    }
  };

  const getClassDetails = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data) ||
        !response_data.response
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      setClassData(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {}
      );
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error!",
        "Something went wrong."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjectsInClass = async () => {
    try {
      if (!subjectsInClassLoading) setSubjectsInClassLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const limit = 6;
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        limit
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        return setSubjects([]);
      }
      setSubjects(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : []
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setSubjectsInClassLoading(false);
    }
  };

  const getNumOfStudents = async () => {
    try {
      if (!noOfStudentsLoading) setNoOfStudentsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getNumOfStudents(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return;
      }
      setNumOfStudents(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Students", "Something went wrong.");
    } finally {
      setNoOfStudentsLoading(false);
    }
  };

  const getNumOfSubjects = async () => {
    try {
      if (!noOfSubjectsLoading) setNoOfSubjectsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getNumOfClassSubjects(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setNumOfSubjects(0);
      }
      setNumOfSubjects(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Subjects", "Something went wrong.");
    } finally {
      setNoOfSubjectsLoading(false);
    }
  };

  const getAssessmentPercentage = async () => {
    try {
      if (!assessmentPercentageLoading) setAssessmentPercentageLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getAssessmentPercentage(
        classId,
        schoolId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return;
      }
      setAssessmentPercentage(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Number of Subjects", "Something went wrong.");
    } finally {
      setAssessmentPercentageLoading(false);
    }
  };

  const getStudentsInClassPerformance = async () => {
    try {
      if (!classPerformanceLoading) setClassPerformanceLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const limit = 10;
      const response = await classApi.getStudentsInClassPerformance(
        classId,
        schoolId,
        termId,
        sessionId,
        limit
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudents([]);
      }
      setStudents(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : []
      );
    } catch (error) {
      responseDailog("error", "Students", "Something went wrong.");
    } finally {
      setClassPerformanceLoading(false);
    }
  };

  const getNumberOfStudentsByGender = async () => {
    try {
      if (!noOfStudentsByGenderLoading) setNoOfStudentsByGenderLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getNumberOfStudentsByGender(
        classId,
        schoolId,
        termId,
        sessionId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return;
      }
      setGenderCounts(
        !empty(response_data) &&
          !empty(response_data.response) &&
          isObject(response_data.response)
          ? response_data.response
          : { male: 0, female: 0 }
      );
    } catch (error) {
      responseDailog("error", "Students", "Something went wrong.");
    } finally {
      setNoOfStudentsByGenderLoading(false);
    }
  };

  const title =
    !empty(classData) && !empty(classData.title) ? classData.title : "";
  const active =
    !empty(classData) && !empty(classData.active) ? classData.active : "";

  const performanceBodyTemplate = (rowData) => {
    const score =
      !empty(rowData) && !empty(rowData.performanceScore)
        ? rowData.performanceScore
        : 0;

    let score_tag = "";
    if (score >= 70) {
      score_tag = <span style={{ color: "green" }}>{score}%</span>;
    } else if (score >= 60 && score <= 69.9) {
      score_tag = <span style={{ color: "#633ccd" }}>{score}%</span>;
    } else if (score >= 50 && score <= 59.9) {
      score_tag = <span style={{ color: "orange" }}>{score}%</span>;
    } else if (score >= 45 && score <= 49.9) {
      score_tag = <span style={{ color: "gray" }}>{score}%</span>;
    } else {
      score_tag = <span style={{ color: "red" }}>{score}%</span>;
    }

    return score_tag;
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {isAuthorized ? (
            <>
              {/* menu header */}
              <MainHeader
                title={title + " Profile"}
                children={
                  <>
                    <ButtonIcon
                      borderColor={colors.primary}
                      backgroundColor={colors.primary}
                      color={colors.white}
                      buttonText="Students"
                      width={120}
                      icon={<FaUserGraduate size={12} />}
                      iconMarginRight={8}
                      marginRight={1}
                      height={30}
                      onClick={() => navigate(`/students/class/${classId}`)}
                    />
                    <ButtonIcon
                      borderColor={colors.primary}
                      backgroundColor={colors.primary}
                      color={colors.white}
                      buttonText="Assessment"
                      width={120}
                      icon={<FaChartLine size={12} />}
                      iconMarginRight={8}
                      marginRight={1}
                      height={30}
                      onClick={() => navigate(`/assessment/set`)}
                    />
                    <ButtonIcon
                      borderColor={colors.primary}
                      backgroundColor={colors.primary}
                      color={colors.white}
                      buttonText="Promotion"
                      icon={<FaForward size={12} />}
                      iconMarginRight={8}
                      width={110}
                      height={30}
                      onClick={() =>
                        navigate(`/students/class/${classId}/promotion`)
                      }
                    />
                  </>
                }
              />
              {/* end menu header */}
              <div
                className="mt-10"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {/* left box */}
                <div id="main_left_box">
                  {/* stat box */}
                  <div className="top_stat_box">
                    <Card
                      children={
                        !noOfStudentsLoading ? (
                          <StatCard
                            underlineWidth={15}
                            title="Students"
                            entry={numOfStudents}
                            icon={
                              <FaUserGraduate
                                size={14}
                                className="stat_card_icon"
                              />
                            }
                          />
                        ) : (
                          <BoxLoading />
                        )
                      }
                      addStyle="card_adjust_stat"
                    />
                    <Card
                      children={
                        !noOfSubjectsLoading ? (
                          <StatCard
                            underlineWidth={15}
                            title="Subjects"
                            entry={numOfSubjects}
                            icon={
                              <FaBookReader
                                size={14}
                                className="stat_card_icon"
                              />
                            }
                          />
                        ) : (
                          <BoxLoading />
                        )
                      }
                      addStyle="card_adjust_stat"
                    />
                    <Card
                      children={
                        !assessmentPercentageLoading ? (
                          <StatCard
                            underlineWidth={15}
                            title="Assessment"
                            entry={assessmentPercentage + "%"}
                            icon={
                              <FaChartLine
                                size={14}
                                className="stat_card_icon"
                              />
                            }
                          />
                        ) : (
                          <BoxLoading />
                        )
                      }
                      addStyle="card_adjust_stat"
                    />
                    <Card
                      children={
                        <StatCard
                          underlineWidth={15}
                          title="Attendance"
                          entry="N/A"
                          icon={
                            <FaChartArea size={14} className="stat_card_icon" />
                          }
                        />
                      }
                      addStyle="card_adjust_stat"
                    />
                  </div>
                  {/* stat header end */}

                  {/* chart */}
                  <div className="inner_chart_box">
                    <Card
                      children={
                        <>
                          <MainHeader title="Students Stat By Gender" />
                          {!noOfStudentsByGenderLoading ? (
                            <DoughnutChart
                              doughnutData={[
                                !empty(genderCounts) &&
                                !empty(genderCounts.male)
                                  ? genderCounts.male
                                  : 0,
                                !empty(genderCounts) &&
                                !empty(genderCounts.female)
                                  ? genderCounts.female
                                  : 0,
                              ]}
                            />
                          ) : (
                            <DonutChartLoading />
                          )}
                        </>
                      }
                      addStyle="px-20"
                      id="chart_box1"
                    />
                    <Card
                      children={
                        <>
                          <MainHeader
                            title="Subjects"
                            rightIcon={
                              <NavLink to={`/class/${classId}/subjects/add`}>
                                <FaRegPlusSquare
                                  style={{
                                    color: colors.primary,
                                    cursor: "pointer",
                                  }}
                                />
                              </NavLink>
                            }
                          />

                          {!subjectsInClassLoading ? (
                            <DataTable
                              value={subjects}
                              tableStyle={{ minWidth: "10rem" }}
                            >
                              <Column field="code" header="Code"></Column>
                              <Column field="title" header="Title"></Column>
                            </DataTable>
                          ) : (
                            <TableLoading rows={6} cols={2} header={false} />
                          )}
                        </>
                      }
                      id="chart_box2"
                    />
                  </div>
                  <div className="chart_box">
                    <Card
                      children={
                        <>
                          <MainHeader
                            title="Top 10 Students"
                            rightIcon={
                              <NavLink
                                to={`/class/${classId}/students/performance`}
                              >
                                others
                              </NavLink>
                            }
                          />
                          <div className="dashboard_school_list">
                            {!classPerformanceLoading ? (
                              <DataTable
                                value={students}
                                tableStyle={{ minWidth: "30rem" }}
                              >
                                <Column
                                  field="regNo"
                                  header="Reg. No."
                                ></Column>
                                <Column
                                  field="fullName"
                                  header="Full Name"
                                ></Column>
                                <Column field="total" header="Total"></Column>
                                <Column
                                  field="performanceScore"
                                  header="Performance"
                                  body={performanceBodyTemplate}
                                ></Column>
                              </DataTable>
                            ) : (
                              <TableLoading rows={10} cols={3} />
                            )}
                          </div>
                        </>
                      }
                      addStyle="student_list_box"
                    />
                  </div>
                  {/* end chart */}

                  {/*  */}
                </div>
                {/* end of left box */}

                {/* right box */}
                <div id="main_right_box">
                  <div className="main_right_boxes">
                    {/* activities */}
                    <Card
                      children={
                        <>
                          <MainHeader
                            title="Activities"
                            rightIcon={<NavLink to="">others</NavLink>}
                          />
                          {/* {ActivityData.map((activity) => {
                            return (
                              <ListTitleSubtitle
                                key={activity.id}
                                date={activity.date}
                                time={activity.time}
                                title={activity.title}
                                subtitle={activity.subtitle}
                                borderColor={activity.color}
                              />
                            );
                          })} */}
                        </>
                      }
                      addStyle="card_adjust"
                    />
                    {/* end of activities */}
                  </div>
                </div>
                {/* end of right box */}
              </div>
            </>
          ) : (
            <div className="inner-unauthorized">
              <FaExclamationTriangle color="red" size={30} />
              <p>
                You do not have the required authorization to view this page.
              </p>
            </div>
          )}
        </main>
        <Toast ref={toastTR} position="bottom-left" />
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>
    </>
  );
};

export default ClassProfile;
