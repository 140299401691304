import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  empty,
  isString,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "./Staff.css";

// api
import staffApi from "../../api/Staff";
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaCheck,
  FaCloudUploadAlt,
  FaEdit,
  FaImage,
  FaRegPlusSquare,
  FaStar,
  FaTimes,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ItemLoading from "../../components/skeleton/ItemLoading";
import BoxLoading from "../../components/skeleton/BoxLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import FullPageLoader from "../../components/loader/FullPageLoader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import colors from "../../config/colors";

const StaffProfile = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [staffData, setStaffData] = useState({});
  const [numberOfClasses, setNumberOfClasses] = useState(0);
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [isSubjectModal, setIsSubjectModal] = useState(false);
  const [selectedClassDetails, setSelectedClassDetails] = useState({});
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [authorizedSubjects, setAuthorizedSubjects] = useState([]);
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [modalType, setModalType] = useState("");
  const [reIndexedClasses, setReIndexedClasses] = useState({});
  const [isUploadAvatarModal, setIsUploadAvatarModal] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState("");
  const [avatar, setAvatar] = useState(
    !empty(staffData) && !empty(staffData.avatar) ? staffData.avatar : ""
  );
  const fileInputRef = useRef(null);
  const location = useLocation();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { staffId } = useParams();
  const toastTR = useRef(null);

  if (!staffId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    // check if a new staff was added or updated
    if (!empty(location) && !empty(location.state)) {
      const paramState = !empty(location.state) ? location.state : {};
      const staffAdded = !empty(paramState.staffAdded)
        ? paramState.staffAdded
        : false;
      const staffUpdated = !empty(paramState.staffUpdated)
        ? paramState.staffUpdated
        : false;
      if (staffAdded === true || staffUpdated === true) {
        const actionType = staffAdded ? "added" : "updated";
        paramState.staffAdded = false;
        paramState.staffUpdated = false;
        responseDailog(
          "success",
          "Success",
          `Staff ${actionType} successfully!`
        );
        if (!empty(location) && !empty(location.state)) {
          navigate(null, {
            replace: true,
            state: { staffAdded: false, staffUpdated: false },
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    getStaffDetails();
    getSubjects();
    getClasses();
  }, [staffId]);

  const getStaffDetails = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getStaffProfile(staffId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const staffDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const classes =
        !empty(response_data) && !empty(staffDetails.assignedClasses)
          ? staffDetails.assignedClasses
          : [];
      const _authorizedSubjects =
        !empty(staffDetails) && !empty(staffDetails.authorizedSubjects)
          ? staffDetails.authorizedSubjects
          : [];
      setAvatar(
        !empty(staffDetails) && !empty(staffDetails.avatar)
          ? staffDetails.avatar
          : ""
      );

      const subjectIds = [];
      for (let i = 0; i < _authorizedSubjects.length; i++) {
        const ids =
          !empty(_authorizedSubjects[i]) &&
          !empty(_authorizedSubjects[i].subjectIds)
            ? _authorizedSubjects[i].subjectIds
            : "";
        subjectIds.push(...ids);
      }
      const subjectIdSet = new Set(subjectIds);

      setAuthorizedSubjects(_authorizedSubjects);
      setAssignedClasses(classes);
      setNumberOfClasses(classes.length);
      setNumOfSubjects(subjectIdSet.size);
      setStaffData(staffDetails);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjects = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        setReIndexedSubjects({});
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        const _reIndexedSubjects = !empty(results)
          ? reIndex(results, "_id")
          : {};
        setReIndexedSubjects(_reIndexedSubjects);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    }
  };

  const getClasses = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      const _reIndexedClasses = !empty(data) ? reIndex(data, "_id") : {};
      setReIndexedClasses(_reIndexedClasses);
    } catch (error) {}
  };

  const assignClassSubjectsToStaff = async () => {
    try {
      if (!actionLoading) setActionLoading(true);
      setIsSubjectModal(false);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const classId =
        !empty(selectedClassDetails) && !empty(selectedClassDetails._id)
          ? selectedClassDetails._id
          : "";
      const response = await staffApi.assignClassSubjectsToStaff(
        staffId,
        schoolId,
        classId,
        modalType
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }

      getStaffDetails();
      return responseDailog(
        "success",
        "Success",
        "Class(es) assigned successfully"
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setActionLoading(false);
    }
  };

  //function to show confirm modal
  const showSubjectModal = (data, type) => {
    setSelectedClassDetails(data);
    setModalType(type);
    setIsSubjectModal(true);
  };

  //function to hide confirm modal
  const hideSubjectModal = () => {
    setIsSubjectModal(false);
  };

  const subjectModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideSubjectModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={assignClassSubjectsToStaff}
      />
    </div>
  );

  // Function to trigger the hidden file input click
  const handleImageFilePickUp = () => {
    fileInputRef.current.click();
  };

  // Function to handle the selected file
  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        // Convert file to Base64 when the file is loaded
        reader.onloadend = async () => {
          const base64String = reader.result;
          setPreviewAvatar(base64String);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {}
  };

  const submitAvatar = async () => {
    // send file to the server
    try {
      if (!actionLoading) setActionLoading(true);
      const staffId =
        !empty(staffData) && !empty(staffData._id) ? staffData._id : "";
      const response = await staffApi.uploadStaffAvatar(
        staffId,
        previewAvatar,
        "staff"
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) &&
            !empty(response_data.response) &&
            isString(response_data.response)
            ? response_data.response
            : "Failed to update staff avatar!"
        );
      }

      // clean data
      setPreviewAvatar("");
      setAvatar(previewAvatar);

      return responseDailog(
        "success",
        "Success",
        "Staff avatar updated successfully."
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setActionLoading(false);
      setIsUploadAvatarModal(false);
    }
  };

  const firstName =
    !empty(staffData) && !empty(staffData.firstName) ? staffData.firstName : "";
  const lastName =
    !empty(staffData) && !empty(staffData.lastName) ? staffData.lastName : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(staffData) && !empty(staffData.middleName)
      ? staffData.middleName
      : "";
  const gender =
    !empty(staffData) && !empty(staffData.gender) ? staffData.gender : "";
  const title =
    !empty(staffData) && !empty(staffData.title) ? staffData.title : "";
  const fullName =
    !empty(staffData) && !empty(staffData.fullName) ? staffData.fullName : "";
  const address =
    !empty(staffData) && !empty(staffData.address) ? staffData.address : "";
  const stateOfResidence =
    !empty(staffData) && !empty(staffData.stateOfResidence)
      ? staffData.stateOfResidence
      : "";
  const lgaOfResidence =
    !empty(staffData) && !empty(staffData.lgaOfResidence)
      ? staffData.lgaOfResidence
      : "";
  const stateOfOrigin =
    !empty(staffData) && !empty(staffData.stateOfOrigin)
      ? staffData.stateOfOrigin
      : "";
  const lgaOfOrigin =
    !empty(staffData) && !empty(staffData.lgaOfOrigin)
      ? staffData.lgaOfOrigin
      : "";
  const nationality =
    !empty(staffData) && !empty(staffData.nationality)
      ? staffData.nationality
      : "";
  const religion =
    !empty(staffData) && !empty(staffData.religion) ? staffData.religion : "";
  const dateOfBirth =
    !empty(staffData) && !empty(staffData.dateOfBirth)
      ? staffData.dateOfBirth
      : "";
  const phoneNumber1 =
    !empty(staffData) && !empty(staffData.phoneNumber1)
      ? staffData.phoneNumber1
      : "";
  const phoneNumber2 =
    !empty(staffData) && !empty(staffData.phoneNumber2)
      ? staffData.phoneNumber2
      : "";
  const email =
    !empty(staffData) && !empty(staffData.email) ? staffData.email : "";
  const qualification =
    !empty(staffData) && !empty(staffData.qualification)
      ? staffData.qualification
      : "";
  const courseStudied =
    !empty(staffData) && !empty(staffData.courseStudied)
      ? staffData.courseStudied
      : "";
  const qrCodePath =
    !empty(staffData) && !empty(staffData.qrCodePath)
      ? staffData.qrCodePath
      : "";

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Staff Profile" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Classes"
                        entry={numberOfClasses}
                        icon={
                          <FaUserGraduate
                            size={12}
                            className="stat_card_icon"
                          />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Subjects"
                        entry={numOfSubjects}
                        icon={
                          <FaBookReader size={12} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="Assessment"
                      entry={assessmentPercentage + "%"}
                      icon={
                        <FaChartLine size={12} className="stat_card_icon" />
                      }
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="Attendance"
                      entry="N/A"
                      icon={
                        <FaChartArea size={12} className="stat_card_icon" />
                      }
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Staff Bio-Data"
                        rightIcon={
                          <NavLink to={`/staff/${staffId}/update`}>
                            <FaEdit
                              style={{ color: "#4338CA", cursor: "pointer" }}
                            />
                          </NavLink>
                        }
                      />
                      <div className="dashboard_school_list">
                        <div className="avatar-container flex">
                          <div className="staff-avatar-container">
                            <p className="fs-20 bold">
                              {title} {fullName}
                            </p>
                            {!empty(avatar) ? (
                              <Avatar
                                image={avatar}
                                shape="circle"
                                style={{
                                  width: 200,
                                  height: 200,
                                  padding: 10,
                                }}
                                className="mb-10"
                              ></Avatar>
                            ) : (
                              <Avatar
                                label={lastNameInitial + firstNameInitial}
                                shape="circle"
                                style={{
                                  width: 150,
                                  height: 150,
                                  fontSize: 70,
                                }}
                                className="mb-10"
                              ></Avatar>
                            )}
                            <div className="">
                              <ButtonIcon
                                height={50}
                                color={colors.primary}
                                backgroundColor="transparent"
                                width={180}
                                fontSize={22}
                                borderColor="transparent"
                                buttonText="Update Avatar"
                                iconMarginRight={8}
                                icon={<FaCloudUploadAlt />}
                                fontWeight="bold"
                                onClick={() => setIsUploadAvatarModal(true)}
                              />
                            </div>
                          </div>
                          <div className="personal-info-block">
                            <div className="personal-info-list">
                              <div className="profile-title">First Name: </div>
                              <div className="profile-value">
                                {!isLoading ? firstName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Last Name: </div>
                              <div className="profile-value">
                                {!isLoading ? lastName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Middle Name: </div>
                              <div className="profile-value">
                                {!isLoading ? middleName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Gender: </div>
                              <div className="profile-value">
                                {!isLoading ? gender : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Title: </div>
                              <div className="profile-value">
                                {!isLoading ? title : <ItemLoading />}
                              </div>
                            </div>
                          </div>
                          <div className="personal-info-block">
                            <div className="personal-info-list">
                              <div className="profile-title">Address: </div>
                              <div className="profile-value">
                                {!isLoading ? address : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                State of Residence:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? (
                                  stateOfResidence
                                ) : (
                                  <ItemLoading />
                                )}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                L.G.A of Residence:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? lgaOfResidence : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                State of Residence:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? stateOfOrigin : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                L.G.A of Origin:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? lgaOfOrigin : <ItemLoading />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="other-staff-info-header">
                          <MainHeader title="Other Information" />
                        </div>
                        <div className="flex sm-reverse-direction-vertical other-staff-info col-g-12">
                          <div className="personal-info-block">
                            <div className="personal-info-list">
                              <div className="profile-title">Nationality: </div>
                              <div className="profile-value">
                                {!isLoading ? nationality : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Religion</div>
                              <div className="profile-value">
                                {!isLoading ? religion : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Date of Birth</div>
                              <div className="profile-value">
                                {!isLoading ? dateOfBirth : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                Phone Number 1
                              </div>
                              <div className="profile-value">
                                {!isLoading ? phoneNumber1 : <ItemLoading />}
                              </div>
                            </div>
                          </div>

                          <div className="personal-info-block">
                            <div className="personal-info-list">
                              <div className="profile-title">
                                Phone Number 2
                              </div>
                              <div className="profile-value">
                                {!isLoading ? phoneNumber2 : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Email: </div>
                              <div className="profile-value">
                                {!isLoading ? email : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Qualification</div>
                              <div className="profile-value">
                                {!isLoading ? qualification : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                Course Studied
                              </div>
                              <div className="profile-value">
                                {!isLoading ? courseStudied : <ItemLoading />}
                              </div>
                            </div>
                          </div>
                          <div className="personal-info-block">
                            <img
                              width={250}
                              height={250}
                              src={qrCodePath}
                              alt="qrcode"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  addStyle="student_list_box"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              <div className="main_right_boxes">
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Assigned Subjects"
                        rightIcon={
                          <NavLink to={`/staff/${staffId}/subjects/assign`}>
                            <FaRegPlusSquare
                              style={{
                                color: colors.primary,
                                cursor: "pointer",
                              }}
                            />
                          </NavLink>
                        }
                      />
                      <div className="mt-10">
                        {!isLoading ? (
                          authorizedSubjects.map((data) => {
                            const _classId =
                              !empty(data) && !empty(data.classId)
                                ? data.classId
                                : "";
                            const _subjectIds =
                              !empty(data) && !empty(data.subjectIds)
                                ? data.subjectIds
                                : [];
                            const _classTitle =
                              reIndexedClasses?.[_classId]?.title || "";

                            const subjects = _subjectIds.map((item) => {
                              const title =
                                !empty(reIndexedSubjects) &&
                                !empty(reIndexedSubjects[item]) &&
                                !empty(reIndexedSubjects[item].title)
                                  ? reIndexedSubjects[item].title
                                  : "";
                              return title;
                            });

                            return (
                              <div
                                key={_classId}
                                className="mb-10 flex direction-column bio-data-item"
                              >
                                <span
                                  style={{
                                    color: "#633ccd",
                                  }}
                                >
                                  <FaStar
                                    style={{
                                      marginRight: 8,
                                      fontSize: 12,
                                    }}
                                    color="#DAA520"
                                  />
                                  {_classTitle}:
                                </span>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 5,
                                    fontSize: 14,
                                  }}
                                >
                                  <ul className="ml-15">
                                    {subjects.map((data, index) => (
                                      <li className="mb-5" key={index}>
                                        {data}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <TableLoading rows={6} cols={1} header={false} />
                        )}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Assigned Class(es)"
                        rightIcon={
                          <NavLink to={`/staff/${staffId}/class/assign`}>
                            <FaRegPlusSquare
                              style={{
                                color: "#389d17",
                                cursor: "pointer",
                              }}
                            />
                          </NavLink>
                        }
                      />
                      <div className="mt-10">
                        {!isLoading ? (
                          assignedClasses.map((item) => {
                            return (
                              <p
                                key={
                                  !empty(item) && !empty(item._id)
                                    ? item._id
                                    : 0
                                }
                                className="mb-10 flex space-between bio-data-item"
                              >
                                <span>
                                  <FaStar
                                    style={{
                                      marginRight: 8,
                                      fontSize: 12,
                                    }}
                                    color="#DAA520"
                                  />
                                  {!empty(item) && !empty(item.title) ? (
                                    <NavLink
                                      style={{
                                        textDecoration: "none",
                                        color: "#633ccd",
                                      }}
                                      to={`/classes/${
                                        !empty(item) && !empty(item._id)
                                          ? item._id + "/profile"
                                          : ""
                                      }`}
                                    >
                                      {item.title}
                                    </NavLink>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#633ccd",
                                  }}
                                >
                                  <NavLink>
                                    <FaCheck
                                      style={{
                                        cursor: "pointer",
                                        color: colors.success,
                                        marginRight: 8,
                                      }}
                                      onClick={() =>
                                        showSubjectModal(item, "assign")
                                      }
                                    />
                                  </NavLink>
                                  <NavLink>
                                    <FaTimes
                                      style={{
                                        cursor: "pointer",
                                        color: colors.danger,
                                      }}
                                      onClick={() =>
                                        showSubjectModal(item, "remove")
                                      }
                                    />
                                  </NavLink>
                                </span>
                              </p>
                            );
                          })
                        ) : (
                          <TableLoading rows={6} cols={1} header={false} />
                        )}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
              </div>
            </div>
            {/* end of right box */}
          </div>
        </main>
        {actionLoading && <FullPageLoader visible={actionLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>

      <Dialog
        visible={isSubjectModal}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={subjectModalFooter}
        onHide={hideSubjectModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {modalType === "assign" ? (
                <span style={{ color: colors.success }}>{modalType}</span>
              ) : (
                <span style={{ color: colors.danger }}>{modalType}</span>
              )}{" "}
              the subjects in{" "}
              <strong>
                {!empty(selectedClassDetails) &&
                !empty(selectedClassDetails.title)
                  ? selectedClassDetails.title
                  : "NA"}
              </strong>{" "}
              {modalType === "assign" ? "to " : "from "} staff,{" "}
              {selectedClassDetails && (
                <b>
                  {lastName} {firstName} {middleName}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>

      <Dialog
        visible={isUploadAvatarModal}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm Action"
        modal
        onHide={() => {
          setPreviewAvatar("");
          setIsUploadAvatarModal(false);
        }}
      >
        <div
          className="confirmation-content"
          style={{
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="avatar-preview-box mb-20">
            {!empty(previewAvatar) ? (
              <img src={previewAvatar} alt="" />
            ) : (
              <FaCloudUploadAlt size={80} />
            )}
          </div>
          {<span>Select image to upload</span>}

          <div className="flex flex-center w-100pc col-g-12">
            <ButtonIcon
              height={40}
              marginTop={1}
              color={colors.tomato}
              backgroundColor={colors.lightgray}
              width={170}
              fontSize={22}
              borderColor={colors.lightgray}
              buttonText="Select Avatar"
              iconMarginRight={8}
              icon={<FaImage />}
              fontWeight="bold"
              onClick={handleImageFilePickUp}
            />
            {previewAvatar ? (
              <ButtonIcon
                height={40}
                marginTop={1}
                color={colors.primary}
                backgroundColor={colors.lightgray}
                width={180}
                fontSize={22}
                borderColor={colors.lightgray}
                buttonText="Update Avatar"
                iconMarginRight={8}
                icon={<FaCloudUploadAlt />}
                fontWeight="bold"
                onClick={() => submitAvatar()}
              />
            ) : null}
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{ display: "none" }}
              accept="image/*"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default StaffProfile;
