import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  empty,
  isArray,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { Toast } from "primereact/toast";
import { isObject, toUpper } from "lodash";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "../staff/Staff.css";

// api
import studentApi from "../../api/Student";
import subjectApi from "../../api/Subject";
import termApi from "../../api/Terms";
import sessionApi from "../../api/Session";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { FaExclamationTriangle, FaPrint } from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import FullPageLoader from "../../components/loader/FullPageLoader";
import colors from "../../config/colors";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const handlePrintTranscript = (ref) => {
  const printContent = ref.current.innerHTML;

  // Create a new window for printing
  const newWindow = window.open("", "", "height=600,width=800");
  newWindow.document.write("<html><head><title>Print Transcript</title>");
  newWindow.document.write(
    "<style>.transcript-box {margin-top: 5px;border-collapse: collapse;width: 100%;}.transcript-box th,.transcript-box td {border: 0.5px solid #000;padding: 5px 2px;text-align: left;min-width: 20px;}.transcript-box th,.transcript-box td {font-size: 14px;padding: 10px;}.transcript-box th {background-color: #f2f2f2;text-align: center;}.transcript-box th[rowSpan='3'],.transcript-box th[rowSpan='4'],.transcript-box th[colSpan='3'],.transcript-box th[colSpan='4'] {text-align: center;}.transcript-box td {text-align: center;}.transcript-box tr td:first-child {text-align: left;min-width: 10px;}.transcript-student-details p {margin-bottom: 10px;}.transcript-student-data {width: 100%;display: flex;flex-direction: row;justify-content: space-between;}</style>"
  );
  newWindow.document.write(printContent); // Print only the transcript table content
  newWindow.document.write("</body></html>");
  newWindow.document.close();
  newWindow.focus();

  // Trigger the print
  newWindow.print();
  newWindow.close();
};

const Transcript = ({ ...props }) => {
  const printRef = useRef();
  const { user } = useContext(AuthContext);
  const [studentData, setStudentData] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [assessmentRecords, setAssessmentRecords] = useState([]);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { studentId } = useParams();
  const toastTR = useRef(null);

  if (!studentId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getStudentDetails();
    getSubjects();
  }, []);

  // function to get all terms
  const getTerms = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await termApi.getTerms(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return [];
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        return data;
      }
    } catch (error) {}
  };

  // function to get all sessions
  const getSessions = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await sessionApi.getSessions(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return [];
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        return data;
      }
    } catch (error) {}
  };

  // get subjects
  const getSubjects = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setSubjects([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        const reIndexedSubjects =
          !empty(data) && isArray(data) ? reIndex(data) : {};
        return setSubjects(reIndexedSubjects);
      }
    } catch (error) {}
  };

  const getStudentDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.getStudentProfile(studentId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        navigate(-1);
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to fetch student details"
        );
      }
      const terms = await getTerms();
      const reIndexedTerms =
        !empty(terms) && isArray(terms) ? reIndex(terms, "_id") : {};
      const sessions = await getSessions();
      const reIndexedSessions =
        !empty(sessions) && isArray(sessions) ? reIndex(sessions, "_id") : {};
      const studentDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const assessmentRecords =
        !empty(studentDetails) && !empty(studentDetails.assessment)
          ? studentDetails.assessment
          : [];
      const transcript = assessmentRecords.reduce((acc, item) => {
        const { sessionId, termId } = item;

        // Check if the sessionId exists, if not create a new session group
        if (!acc[sessionId]) {
          acc[sessionId] = {};
        }

        // Check if the termId exists under the sessionId, if not create a new term group
        if (!acc[sessionId][termId]) {
          acc[sessionId][termId] = [];
        }

        // Push the item into the term group
        acc[sessionId][termId].push(item);

        return acc;
      }, {});
      const groupedTranscript = Object.entries(transcript).map(
        ([sessionId, terms]) => {
          const sessionTitle =
            !empty(reIndexedSessions) &&
            !empty(reIndexedSessions[sessionId]) &&
            !empty(reIndexedSessions[sessionId].title)
              ? reIndexedSessions[sessionId].title
              : "";
          return {
            sessionId,
            sessionTitle,
            terms: Object.entries(terms).map(([termId, assessments]) => ({
              termId,
              termTitle:
                !empty(reIndexedTerms) &&
                !empty(reIndexedTerms[termId]) &&
                !empty(reIndexedTerms[termId].title)
                  ? reIndexedTerms[termId].title
                  : "",
              assessments,
            })),
          };
        }
      );

      setAssessmentRecords(groupedTranscript);
      return setStudentData(studentDetails);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const firstName =
    !empty(studentData) && !empty(studentData.firstName)
      ? studentData.firstName
      : "";
  const lastName =
    !empty(studentData) && !empty(studentData.lastName)
      ? studentData.lastName
      : "";
  const middleName =
    !empty(studentData) && !empty(studentData.middleName)
      ? studentData.middleName
      : "";
  const fullName = toUpper(lastName) + ", " + firstName + " " + middleName;
  const gender =
    !empty(studentData) && !empty(studentData.gender) ? studentData.gender : "";
  const regNo =
    !empty(studentData) && !empty(studentData.regNo) ? studentData.regNo : "";
  const email =
    !empty(studentData) && !empty(studentData.email) ? studentData.email : "";
  const hasPaid =
    !empty(studentData) && !empty(studentData.paid) ? studentData.paid : "No";
  const graduated =
    !empty(studentData) && !empty(studentData.graduationClassId) ? true : false;
  const graduationYear =
    !empty(studentData) && !empty(studentData.graduationYear)
      ? studentData.graduationYear
      : "Active Student";

  // hide activation modal
  const hideActivationModal = () => {
    setActivationModalOpen(false);
  };

  //function to activate student on payment
  const activateStudent = async () => {
    try {
      if (activationModalOpen) setActivationModalOpen(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(studentData) && !empty(studentData._id) ? studentData._id : "";
      const response = await studentApi.activateStudent([studentId], schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to activate student`;
        return responseDailog("error", "Error Alert", error_response);
      }

      const studentsDetails = await getStudentDetails();
      setStudentData(studentsDetails);
      return responseDailog(
        "success",
        "Success",
        `Activated student successfully!`
      );
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const activateStudentDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideActivationModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() => {
          activateStudent();
        }}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Student Transcript" />
          {hasPaid || graduated ? (
            <ButtonIcon
              buttonText="Print"
              icon={<FaPrint />}
              iconMarginRight={8}
              backgroundColor="transparent"
              color={colors.primary}
              borderColor={colors.primary}
              height={30}
              width={150}
              onClick={() => handlePrintTranscript(printRef)}
            />
          ) : null}
          <div ref={printRef}>
            <div className="transcript-student-data mt-30">
              <div className="transcript-student-details">
                <p>
                  <strong>Student Name: </strong> {fullName}
                </p>
                <p>
                  <strong>Reg. No.: </strong> {regNo}
                </p>
                <p>
                  <strong>Gender: </strong> {gender}
                </p>
                <p>
                  <strong>Email: </strong> {email}
                </p>
                <p>
                  <strong>Graduation Year: </strong> {graduationYear}
                </p>
              </div>
            </div>
            {/* end menu header */}
            {console.log(assessmentRecords)}
            {hasPaid || graduated ? (
              <div style={{ width: "100%", marginTop: 10 }}>
                <div>
                  {!empty(assessmentRecords) && isArray(assessmentRecords) ? (
                    assessmentRecords.map((session, index) => {
                      return (
                        <table className="transcript-box" key={index}>
                          {/* Loop through each term in the session */}
                          {!empty(session) &&
                          isObject(session) &&
                          !empty(session.terms) &&
                          isArray(session.terms)
                            ? session.terms.map((term, index) => {
                                const termTitle =
                                  !empty(term) && !empty(term.termTitle)
                                    ? term.termTitle
                                    : "";
                                const sessionTitle =
                                  !empty(session) &&
                                  !empty(session.sessionTitle)
                                    ? session.sessionTitle
                                    : "";
                                return (
                                  <>
                                    <thead>
                                      {/* Display the term row */}
                                      <caption
                                        style={{
                                          marginTop: 40,
                                          marginBottom: 10,
                                          width: 500,
                                          textAlign: "left",
                                        }}
                                        key={index}
                                      >
                                        <strong>{`${termTitle}, ${sessionTitle} Academic Session`}</strong>
                                      </caption>
                                      <tr>
                                        <th>Subject</th> <th>CA1</th>
                                        <th>CA2</th>
                                        <th>Exam</th>
                                        <th>Total</th>
                                        <th>Grade</th>
                                        <th>Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* Loop through each assessment in the term */}
                                      {!empty(term) &&
                                      isObject(term) &&
                                      !empty(term.assessments) &&
                                      isArray(term.assessments)
                                        ? term.assessments.map(
                                            (assessment, index) => {
                                              if (!empty(assessment)) {
                                                const subjectId = !empty(
                                                  assessment.subjectId
                                                )
                                                  ? assessment.subjectId
                                                  : "";
                                                const ca1 = !empty(
                                                  assessment.ca1
                                                )
                                                  ? assessment.ca1
                                                  : 0;
                                                const ca2 = !empty(
                                                  assessment.ca2
                                                )
                                                  ? assessment.ca2
                                                  : 0;
                                                const exam = !empty(
                                                  assessment.exam
                                                )
                                                  ? assessment.exam
                                                  : 0;
                                                const total = !empty(
                                                  assessment.total
                                                )
                                                  ? assessment.total
                                                  : 0;
                                                const grade = !empty(
                                                  assessment.grade
                                                )
                                                  ? assessment.grade
                                                  : 0;
                                                const remark = !empty(
                                                  assessment.remark
                                                )
                                                  ? assessment.remark
                                                  : 0;
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <strong>
                                                        {!empty(subjects) &&
                                                        isObject(subjects) &&
                                                        !empty(
                                                          subjects[subjectId]
                                                        ) &&
                                                        !empty(
                                                          subjects[subjectId]
                                                            .title
                                                        )
                                                          ? toUpper(
                                                              subjects[
                                                                subjectId
                                                              ].title
                                                            )
                                                          : "N/A"}
                                                      </strong>
                                                    </td>
                                                    <td>{ca1}</td>
                                                    <td>{ca2}</td>
                                                    <td>{exam}</td>
                                                    <td>{total}</td>
                                                    <td>{grade}</td>
                                                    <td>{toUpper(remark)}</td>
                                                  </tr>
                                                );
                                              }
                                              return null;
                                            }
                                          )
                                        : null}
                                    </tbody>
                                  </>
                                );
                              })
                            : null}
                        </table>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-50 assessment_not_paid">
                <FaExclamationTriangle color="red" size={25} />
                <p className="mt-10 fs-18 mb-5">
                  <strong>Result Sheet Unavailable</strong>
                </p>
                <p>This student has an outstanding portal levy</p>
                <div>
                  <ButtonIcon
                    height={45}
                    color={colors.primary}
                    backgroundColor="transparent"
                    width={220}
                    fontWeight="bold"
                    borderColor="transparent"
                    buttonText="Activate Student"
                    onClick={() => setActivationModalOpen(true)}
                  />
                </div>
              </div>
            )}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />

      <Dialog
        visible={activationModalOpen}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={activateStudentDialogFooter}
        onHide={hideActivationModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <>
              <p>
                Are you sure you want to <strong>activate</strong> this student?{" "}
                <br />
                <br />
                By clicking "Yes", you are confirming that{" "}
                <strong>{fullName}</strong> made portal payment for this term.
                <br />
                <br />
                <em style={{ color: colors.danger }}>
                  <strong>Note:</strong> This action cannot be undone.
                </em>
              </p>
            </>
          }
        </div>
      </Dialog>
    </>
  );
};

export default Transcript;
