const APP_BASE_URL = "https://school.anciemdemia.com";
const API_SCHOOL_URL = "https://api.anciemdemia.com/v1/school";
const API_BASE_URL = "https://api.anciemdemia.com/v1";

// const APP_BASE_URL = "http://172.20.10.9:3002";
// const API_SCHOOL_URL = "http://172.20.10.9:8052/school";
// const API_BASE_URL = "http://172.20.10.9:8052";

// const APP_BASE_URL = "https://staging-school.anciemdemia.com";
// const API_SCHOOL_URL = "https://api.staging.anciemdemia.com/v1/school";
// const API_BASE_URL = "https://api.staging.anciemdemia.com/v1";

export { API_BASE_URL, API_SCHOOL_URL, APP_BASE_URL };
